import React from "react";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";

import "./App.css";

export default function App() {
  return (
    <div className="App">
      <img src="/logo.png" alt="" className="logo" />
      <div className="container">
        <h1>
          Website
          <br />
          Under Maintenance
        </h1>
        <Timer />
        {/* <Optin /> */}
        <Preloader />
      </div>
    </div>
  );
}
